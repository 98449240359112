import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const UploadIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 41}
      height={height || 40}
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M20.746 17.9295C20.7168 17.8921 20.6794 17.8619 20.6368 17.8411C20.5941 17.8204 20.5473 17.8096 20.4999 17.8096C20.4525 17.8096 20.4057 17.8204 20.363 17.8411C20.3204 17.8619 20.283 17.8921 20.2538 17.9295L15.8788 23.4646C15.8427 23.5107 15.8203 23.566 15.8142 23.6241C15.8081 23.6823 15.8184 23.741 15.8441 23.7936C15.8698 23.8462 15.9097 23.8905 15.9594 23.9214C16.009 23.9523 16.0664 23.9687 16.1249 23.9685H19.0116V33.4373C19.0116 33.6092 19.1522 33.7498 19.3241 33.7498H21.6679C21.8397 33.7498 21.9804 33.6092 21.9804 33.4373V23.9724H24.8749C25.1366 23.9724 25.2811 23.6717 25.121 23.4685L20.746 17.9295Z"
        fill={fillStyle || '#03341D'}
      />
      <path
        d="M32.1953 14.3242C30.4063 9.60547 25.8477 6.25 20.5078 6.25C15.168 6.25 10.6094 9.60156 8.82031 14.3203C5.47266 15.1992 3 18.25 3 21.875C3 26.1914 6.49609 29.6875 10.8086 29.6875H12.375C12.5469 29.6875 12.6875 29.5469 12.6875 29.375V27.0312C12.6875 26.8594 12.5469 26.7188 12.375 26.7188H10.8086C9.49219 26.7188 8.25391 26.1953 7.33203 25.2461C6.41406 24.3008 5.92578 23.0273 5.96875 21.707C6.00391 20.6758 6.35547 19.707 6.99219 18.8906C7.64453 18.0586 8.55859 17.4531 9.57422 17.1836L11.0547 16.7969L11.5977 15.3672C11.9336 14.4766 12.4023 13.6445 12.9922 12.8906C13.5745 12.1434 14.2643 11.4865 15.0391 10.9414C16.6445 9.8125 18.5352 9.21484 20.5078 9.21484C22.4805 9.21484 24.3711 9.8125 25.9766 10.9414C26.7539 11.4883 27.4414 12.1445 28.0234 12.8906C28.6133 13.6445 29.082 14.4805 29.418 15.3672L29.957 16.793L31.4336 17.1836C33.5508 17.7539 35.0312 19.6797 35.0312 21.875C35.0312 23.168 34.5273 24.3867 33.6133 25.3008C33.165 25.7517 32.6318 26.1092 32.0444 26.3526C31.457 26.596 30.8272 26.7204 30.1914 26.7188H28.625C28.4531 26.7188 28.3125 26.8594 28.3125 27.0312V29.375C28.3125 29.5469 28.4531 29.6875 28.625 29.6875H30.1914C34.5039 29.6875 38 26.1914 38 21.875C38 18.2539 35.5352 15.207 32.1953 14.3242Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default UploadIcon
