import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'

type RadioSelectOption = {
  label: string | React.ReactNode
  subLabel?: string
  value: any
}

export type RadioSelectProps = RadioGroupProps & {
  label?: string | React.ReactNode
  labelSize?: 'small' | 'large'
  optionSize?: 'p1' | 'p2'
  caption?: string
  optional?: boolean
  error?: boolean
  optionalTextOverride?: string
  options: RadioSelectOption[]
  'data-testid'?: string
  mobileRowOverride?: boolean
}

const RadioSelect = (props: RadioSelectProps) => {
  const {
    options,
    label,
    labelSize,
    optionSize,
    caption,
    optional,
    optionalTextOverride,
    error,
    row,
    mobileRowOverride,
    'data-testid': dataTestId,
    ...radioGroupProps
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const optionalText = optionalTextOverride ?? '(optional)'

  let gap = '6px'
  if (row && !isMobile) {
    gap = '12px'
  } else if (isMobile) {
    gap = '12px'
  }
  return (
    <FormControl
      error={error}
      sx={{ gap: '12px' }}
      data-testid={dataTestId}
      data-type="radio"
      data-options={options.map((option) => option.value).join(',')}
    >
      {label && (
        <FormLabel sx={{ color: error ? 'error.main' : 'gray8.main' }}>
          <Typography variant={labelSize === 'small' ? 'p2' : 'h4'}>
            {label}
            <Typography
              component="span"
              variant={labelSize === 'small' ? 'p2' : 'h4'}
              color="gray5.main"
              display="inline"
            >
              {optional && ` ${optionalText}`}
            </Typography>
          </Typography>
        </FormLabel>
      )}
      {caption && (
        <FormLabel sx={{ color: error ? 'error.main' : 'gray8.main' }}>
          <Typography
            component="span"
            variant={labelSize === 'small' ? 'p3' : 'h4'}
            color="gray5.main"
          >
            {caption}
          </Typography>
        </FormLabel>
      )}
      <RadioGroup
        sx={{ gap }}
        row={(!isMobile || mobileRowOverride) && row}
        {...radioGroupProps}
      >
        {options.map((option) => {
          const { label: optionLabel, subLabel, value } = option
          return (
            <FormControlLabel
              key={value}
              value={value}
              control={
                <Radio
                  size="small"
                  data-testid={`${dataTestId}-${value}`}
                  data-type="radio-select-option"
                  color={error ? 'error' : undefined}
                  sx={{
                    '& > span': {
                      color: error ? 'error.main' : undefined,
                    },
                  }}
                />
              }
              sx={{
                alignItems: 'flex-start',
              }}
              label={
                <Box
                  display="flex"
                  flexDirection="column"
                  paddingTop={
                    optionSize === 'p2'
                      ? // @ts-ignore
                        theme?.radioSelectOptions?.p1Padding || '7px'
                      : // @ts-ignore
                        theme?.radioSelectOptions?.p2Padding || '5px'
                  }
                >
                  <Typography
                    // @ts-ignore
                    variant={
                      optionSize ||
                      theme?.radioSelectOptions?.labelVariant ||
                      'p1'
                    }
                    color={error ? 'error.main' : undefined}
                  >
                    {optionLabel}
                  </Typography>
                  {subLabel && !isMobile && (
                    <Typography
                      // @ts-ignore
                      variant={
                        // @ts-ignore
                        theme?.radioSelectOptions?.subLabelVariant || 'p3'
                      }
                    >
                      {subLabel}
                    </Typography>
                  )}
                </Box>
              }
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioSelect

RadioSelect.defaultProps = {
  label: '',
  labelSize: 'large',
  optional: false,
  optionalTextOverride: undefined,
  error: false,
  'data-testid': undefined,
}
