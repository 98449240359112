import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const PdfIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 22}
      height={height || 28}
      viewBox="0 0 22 28"
      fill="none"
    >
      <path
        d="M11.6031 15.95L11.6125 15.9062C11.7937 15.1594 12.0219 14.2281 11.8438 13.3844C11.725 12.7188 11.2344 12.4594 10.8156 12.4406C10.3219 12.4187 9.88125 12.7 9.77188 13.1094C9.56563 13.8594 9.75 14.8844 10.0875 16.1906C9.6625 17.2031 8.98438 18.675 8.4875 19.55C7.5625 20.0281 6.32187 20.7656 6.1375 21.6969C6.1 21.8687 6.14375 22.0875 6.24687 22.2844C6.3625 22.5031 6.54688 22.6719 6.7625 22.7531C6.85625 22.7875 6.96875 22.8156 7.1 22.8156C7.65 22.8156 8.54062 22.3719 9.72812 20.3344C9.90937 20.275 10.0969 20.2125 10.2781 20.15C11.1281 19.8625 12.0094 19.5625 12.8062 19.4281C13.6875 19.9 14.6906 20.2031 15.3719 20.2031C16.0469 20.2031 16.3125 19.8031 16.4125 19.5625C16.5875 19.1406 16.5031 18.6094 16.2188 18.325C15.8062 17.9188 14.8031 17.8125 13.2406 18.0063C12.4719 17.5375 11.9688 16.9 11.6031 15.95ZM8.175 20.6969C7.74063 21.3281 7.4125 21.6437 7.23438 21.7812C7.44375 21.3969 7.85313 20.9906 8.175 20.6969ZM10.9125 13.3375C11.075 13.6156 11.0531 14.4563 10.9281 14.8813C10.775 14.2594 10.7531 13.3781 10.8438 13.275C10.8687 13.2781 10.8906 13.2969 10.9125 13.3375ZM10.8625 17.1031C11.1969 17.6812 11.6188 18.1781 12.0844 18.5469C11.4094 18.7 10.7937 18.9531 10.2437 19.1781C10.1125 19.2313 9.98438 19.2844 9.85938 19.3344C10.275 18.5813 10.6219 17.7281 10.8625 17.1031ZM15.725 19.15C15.7281 19.1562 15.7312 19.1656 15.7125 19.1781H15.7062L15.7 19.1875C15.675 19.2031 15.4187 19.3531 14.3156 18.9188C15.5844 18.8594 15.7219 19.1469 15.725 19.15ZM21.7062 7.01875L14.9813 0.29375C14.7938 0.10625 14.5406 0 14.275 0H1C0.446875 0 0 0.446875 0 1V27C0 27.5531 0.446875 28 1 28H21C21.5531 28 22 27.5531 22 27V7.72812C22 7.4625 21.8937 7.20625 21.7062 7.01875ZM19.6938 8.1875H13.8125V2.30625L19.6938 8.1875ZM19.75 25.75H2.25V2.25H11.6875V9C11.6875 9.3481 11.8258 9.68194 12.0719 9.92808C12.3181 10.1742 12.6519 10.3125 13 10.3125H19.75V25.75Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default PdfIcon
