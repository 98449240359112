import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const LeftArrowIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M22.1939 9L24.7194 11.5446L14.3222 22.1585L41.25 22.1585L41.25 25.8415L14.3222 25.8415L24.7194 36.4219L22.1939 39L7.5 24L22.1939 9Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default LeftArrowIcon
