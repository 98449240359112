import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextLoop from 'react-text-loop'
import Whiteboard from '../../../images/whiteboard.svg'

const TextSpan = styled(Box)({
  textAlign: 'center',
  width: '500px',
})

export type LoadingBackgroundProps = {
  backgroundTransition?: boolean
  ready?: boolean
  hideTabletBackground?: boolean
}

const LoadingBackground = (props: LoadingBackgroundProps) => {
  const { backgroundTransition, ready } = props
  const [isReady, setIsReady] = useState(ready)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    if (!ready) {
      setTimeout(() => {
        setIsReady(true)
      }, 5000)
    }
    setImageLoaded(true)
  }, [ready])

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  if (isTablet) {
    return null
  }

  let finalMessage = `Your preliminary offer page is ready, please answer the remaining question to continue.`
  if (ready) {
    finalMessage = 'Redirecting you to your preliminary offer page...'
  }

  return (
    <Box
      position="absolute"
      height="100%"
      width={{
        xs: '100vw',
        md: '50vw',
      }}
      left="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="122px"
      sx={{
        backgroundColor: 'darkBackground.main',
      }}
    >
      <Box
        component="img"
        src={Whiteboard}
        width="392px"
        height="332px"
        sx={{
          transition: backgroundTransition ? 'opacity 300ms ease' : undefined,
          opacity: imageLoaded ? 1 : 0,
        }}
      />
      <Typography
        variant="h4"
        color="white.main"
        textAlign="center"
        maxWidth="500px"
      >
        {isReady ? (
          finalMessage
        ) : (
          <TextLoop>
            <TextSpan>Computing vacation home comps in your area...</TextSpan>
            <TextSpan>Analyzing market conditions...</TextSpan>
            <TextSpan>Calculating yield and appreciation potential...</TextSpan>
          </TextLoop>
        )}
      </Typography>
    </Box>
  )
}

export default LoadingBackground
