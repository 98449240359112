import React from 'react'
import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material'

export type CheckboxProps = MuiCheckboxProps & {
  label?: string | React.ReactNode
  error?: boolean
  disabled?: boolean
  'data-testid'?: string
}

const Checkbox = (props: CheckboxProps) => {
  const {
    label,
    error,
    disabled,
    checked,
    'data-testid': dataTestId,
    ...otherProps
  } = props
  const theme = useTheme()

  return (
    <Box sx={{ width: '100%' }}>
      <FormControlLabel
        control={
          <MuiCheckbox
            {...otherProps}
            inputProps={{
              // @ts-ignore
              'data-testid': dataTestId,
              'data-type': 'checkbox',
            }}
            sx={{
              color: error && !disabled ? 'errorRed.main' : 'primary.main',
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
            checked={checked || false}
          />
        }
        sx={{
          alignItems: 'flex-start',
          color: error && !disabled ? 'error.main' : 'primary.main',
        }}
        label={
          <Typography
            variant="p2"
            // @ts-ignore
            sx={{ marginTop: theme?.checkboxOptions?.labelMargin || '0px' }}
          >
            {label}
          </Typography>
        }
        disabled={disabled}
      />
    </Box>
  )
}

export default Checkbox
