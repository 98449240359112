import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

type CheckboxOption = {
  label: string
  value: string
}

export type MultiCheckboxProps = MuiCheckboxProps & {
  onChange: (value: string[]) => void
  label: string
  error: boolean
  options: CheckboxOption[]
  required?: boolean
  'data-testid'?: string
}

const MultiCheckbox = (props: MultiCheckboxProps) => {
  const {
    options,
    onChange,
    label,
    required,
    error: gridFormError,
    'data-testid': dataTestId,
    ...muiCheckboxProps
  } = props
  const [checkedValues, setCheckValues] = useState<string[]>([])
  // handle errors internally in component instead of using gridForm errors because
  // some async issue between updating value and updating error in form (seems like error state gets updated then value)
  // gridForm error will still prevent from submitting form if required.
  const [hasError, setHasError] = useState<boolean>(false)
  const handleSelect = (checkedName: string) => {
    const newValues = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName]
    setCheckValues(newValues)
    onChange(newValues)
    if (required) {
      setHasError(!newValues?.length)
    }
    return newValues
  }
  // even though we manage error internally, it doesn't check trigger on onsubmit of form.
  // so we use internal error state as source of truth, but gridform error is update dependency.
  useEffect(() => {
    // we check if gridForm error is true first so we don't set error on initial render
    if (gridFormError && required) {
      setHasError(!checkedValues?.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFormError])
  const textColor = hasError ? 'errorRed.main' : 'gray8.main'
  const { control } = useForm()
  return (
    <form>
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        sx={{ width: '100%' }}
      >
        <Typography variant="p2" color={textColor}>
          {label}
        </Typography>
        {options.map(({ label: checkboxLabel, value }) => (
          <FormControlLabel
            control={
              <Controller
                name={value}
                render={({ field: { onChange: onCheckChange } }) => (
                  <MuiCheckbox
                    {...muiCheckboxProps}
                    data-testid={dataTestId}
                    data-type="checkbox"
                    checked={checkedValues.includes(value)}
                    onChange={() => onCheckChange(handleSelect(value))}
                    sx={{
                      color: textColor,
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }}
                  />
                )}
                control={control}
              />
            }
            key={value}
            sx={{ alignItems: 'flex-start', color: textColor }}
            label={
              <Typography variant="p2" color={textColor}>
                {checkboxLabel}
              </Typography>
            }
          />
        ))}
        <Typography variant="p2" color="errorRed.main">
          {hasError && 'This field is required'}
        </Typography>
      </Box>
    </form>
  )
}

MultiCheckbox.defaultProps = {
  required: false,
}

export default MultiCheckbox
