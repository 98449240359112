import { formatDollarsAndCents } from '@flock/utils'
import { Box, Slider, styled, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import theme from '../../theme/theme'
import { InfoIcon } from '../icons/InfoIcon'

const StyledSlider = styled((props: any) => (
  <Slider classes={{ popper: props.className }} {...props} />
))`
  color: ${theme.palette.exclamationRed.main};

  & .MuiSlider-thumb {
    color: ${theme.palette.exclamationRed.main};
  }

  & .MuiSlider-track {
    color: ${theme.palette.exclamationRed.main};
    border: none;
  }

  & .MuiSlider-rail {
    opacity: 1;
    background-color: ${theme.palette.primary.main};
  }

  & .MuiSlider-mark {
    height: 16px;
    opacity: 1;
    background-color: ${theme.palette.primary.main};
  }

  & .MuiSlider-markActive {
    opacity: 1;
    background-color: ${theme.palette.exclamationRed.main};
  }

  & .MuiSlider-markLabel {
    whitespace: normal;
    overflowwrap: normal;
    lineheight: 2;
    color: ${theme.palette.primary.main};
  }
`

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export type PercentageSliderProps = {
  title: string
  tooltip?: string
  defaultValue?: number
  maxAmount?: number
  showPercentages?: boolean
  includeExplanation?: boolean
  onChange: (newValue: number) => void
}

const PercentageSlider = (props: PercentageSliderProps) => {
  const {
    title,
    tooltip,
    defaultValue,
    maxAmount,
    includeExplanation,
    showPercentages,
    onChange,
  } = props
  const [percent, setPercent] = useState(defaultValue || 0)

  const onChangeSetting = (_: Event, newValue: number | number[]) => {
    setPercent(newValue as number)
    onChange(newValue as number)
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">{title}</Typography>
        {tooltip && (
          <Box>
            <Tooltip
              title={tooltip}
              arrow
              placement="right-start"
              enterTouchDelay={0}
            >
              <Box sx={{ cursor: 'pointer' }}>
                <InfoIcon height="16px" width="16px" />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '8px',
          paddingBottom: '24px',
        }}
      >
        <StyledSlider
          value={percent}
          step={25}
          marks={[
            {
              value: 0,
              label: (
                <StyledBox>
                  {showPercentages && <Typography variant="p3">0%</Typography>}
                  <Typography variant="p3">
                    {maxAmount ? '$0.00' : ''}
                  </Typography>
                </StyledBox>
              ),
            },
            {
              value: 25,
              label: (
                <StyledBox>
                  {showPercentages && <Typography variant="p3">25%</Typography>}
                  <Typography variant="p3">
                    {maxAmount ? formatDollarsAndCents(0.25 * maxAmount) : ''}
                  </Typography>
                </StyledBox>
              ),
            },
            {
              value: 50,
              label: (
                <StyledBox>
                  {showPercentages && <Typography variant="p3">50%</Typography>}
                  <Typography variant="p3">
                    {maxAmount ? formatDollarsAndCents(0.5 * maxAmount) : ''}
                  </Typography>
                </StyledBox>
              ),
            },
            {
              value: 75,
              label: (
                <StyledBox>
                  {showPercentages && <Typography variant="p3">75%</Typography>}
                  <Typography variant="p3">
                    {maxAmount ? formatDollarsAndCents(0.75 * maxAmount) : ''}
                  </Typography>
                </StyledBox>
              ),
            },
            {
              value: 100,
              label: (
                <StyledBox>
                  {showPercentages && (
                    <Typography variant="p3">100%</Typography>
                  )}
                  <Typography variant="p3">
                    {maxAmount ? formatDollarsAndCents(maxAmount) : ''}
                  </Typography>
                </StyledBox>
              ),
            },
          ]}
          onChange={onChangeSetting}
          aria-label="Percentage slider"
          sx={{
            width: '95%',
          }}
        />
      </Box>
      {includeExplanation && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              paddingBottom: '16px',
            }}
          >
            <Typography
              variant="p1"
              sx={{ color: theme.palette.exclamationRed.main }}
            >
              {percent}% cash
            </Typography>
            <Typography variant="p1" sx={{ color: theme.palette.primary.main }}>
              {100 - percent}% reinvest
            </Typography>
          </Box>
          <StyledBox>
            {percent > 0 ? (
              <>
                {percent === 100 ? (
                  <Typography variant="p3">
                    I want to receive 100% in cash for my quarterly
                    distribution.
                  </Typography>
                ) : (
                  <Typography variant="p3">
                    I want to receive {percent}% in cash and reinvest{' '}
                    {100 - percent}% and receive equity for my quarterly
                    distribution.
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="p3">
                I want to reinvest 100% and receive equity for my quarterly
                distribution.
              </Typography>
            )}
          </StyledBox>
        </>
      )}
    </Box>
  )
}

export default PercentageSlider
