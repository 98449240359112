import { Box, TextField, TextFieldProps } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState, useEffect } from 'react'
import { SxProps } from '@mui/system'
import { LeftArrowIcon } from '../icons/LeftArrowIcon'
import { RightArrowIcon } from '../icons/RightArrowIcon'
import { CaretIcon } from '../icons/CaretIcon'

export type DateTimePickerComponentProps = Omit<TextFieldProps, 'onChange'> & {
  value?: Date | null | undefined
  defaultValue?: Date | null | undefined
  onChange?: (event: { target: { value: Date | null | undefined } }) => void
  disablePast?: boolean
  disableFuture?: boolean
  centered?: boolean
  'data-testid'?: string
}

const popperSx: SxProps = {
  '& .MuiClock-pmButton': {
    height: '36px',
    width: '36px',
    fontSize: '.75em',
  },
  '& .MuiClock-amButton': {
    height: '36px',
    width: '36px',
    fontSize: '.75em',
  },
}

const DateTimePickerComponent = (props: DateTimePickerComponentProps) => {
  const {
    value,
    onChange,
    onBlur,
    disablePast,
    disableFuture,
    centered,
    'data-testid': dataTestId,
    ...otherProps
  } = props
  const [selectedDate, setSelectedDate] = React.useState<
    Dayjs | null | undefined
  >(value as any)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (value) {
      setSelectedDate(dayjs(value))
    }
  }, [value])

  const handleChange = (newValue: Dayjs | null | undefined) => {
    setSelectedDate(newValue)
    // @ts-ignore
    onChange({ target: { value: newValue?.toDate() } })
  }

  const onClose = () => {
    setOpen(false)
    if (onBlur) {
      onBlur({ target: { value: 0 } } as any)
    }
  }

  const onTrueBlur = (e: any) => {
    if (onBlur && !open) {
      onBlur(e)
    }
  }

  const { label } = otherProps

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        label={label}
        value={selectedDate}
        open={open}
        onClose={onClose}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        onChange={handleChange}
        components={{
          LeftArrowIcon: () => <LeftArrowIcon width="24px" height="24px" />,
          RightArrowIcon: () => <RightArrowIcon width="24px" height="24px" />,
          SwitchViewIcon: (switchViewIconProps) => {
            const { className } = switchViewIconProps
            return (
              <Box
                className={className}
                display="flex"
                alignItems="center"
                sx={{ pointerEvents: 'none' }}
              >
                <CaretIcon width="16px" height="16px" />
              </Box>
            )
          },
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            {...otherProps}
            {...params}
            data-testid={dataTestId}
            data-type="date-time-picker"
            variant={otherProps.variant as any}
            onBlur={onTrueBlur}
            sx={{
              ...otherProps.sx,
              '& input': {
                textAlign: centered ? 'center' : 'left',
              },
            }}
            InputProps={{
              notched: false,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onClick={() => setOpen(true)}
            error={otherProps.error}
          />
        )}
        PopperProps={{
          sx: popperSx,
        }}
      />
    </LocalizationProvider>
  )
}

export default DateTimePickerComponent

DateTimePickerComponent.defaultProps = {
  disablePast: false,
  disableFuture: false,
  centered: false,
}
