import { Box, TextField, TextFieldProps, useMediaQuery } from '@mui/material'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState, useEffect } from 'react'
import { flockTheme } from '../../theme'
import { LeftArrowIcon } from '../icons/LeftArrowIcon'
import { RightArrowIcon } from '../icons/RightArrowIcon'
import { CaretIcon } from '../icons/CaretIcon'

const dayFormat: { [key: string]: string } = {
  Mo: 'Mon',
  Tu: 'Tue',
  We: 'Wed',
  Th: 'Thu',
  Fr: 'Fri',
  Sa: 'Sat',
  Su: 'Sun',
}

export type DatePickerProps = Omit<TextFieldProps, 'onChange'> & {
  value: Date | null | undefined
  onChange: (event: { target: { value: Date | null | undefined } }) => void
  disablePast?: boolean
  disableFuture?: boolean
  centered?: boolean
  'data-testid'?: string
}

const DatePicker = (props: DatePickerProps) => {
  const {
    value,
    onChange,
    onBlur,
    disablePast,
    disableFuture,
    centered,
    'data-testid': dataTestId,
    ...otherProps
  } = props
  const [selectedDate, setSelectedDate] = React.useState<
    Dayjs | null | undefined
  >(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (value) {
      setSelectedDate(dayjs(value))
    }
  }, [value])

  const handleChange = (newValue: Dayjs | null | undefined) => {
    setSelectedDate(newValue)
    onChange({ target: { value: newValue?.toDate() } })
  }

  const onClose = () => {
    setOpen(false)
    if (onBlur) {
      onBlur({ target: { value: 0 } } as any)
    }
  }

  const onTrueBlur = (e: any) => {
    if (onBlur && !open) {
      onBlur(e)
    }
  }

  const isMobile = useMediaQuery(flockTheme.breakpoints.down('sm'))

  const { label } = otherProps

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isMobile ? (
        <MobileDatePicker
          value={selectedDate}
          onChange={handleChange}
          inputFormat="MM/DD/YYYY"
          toolbarTitle=""
          disableFuture={disableFuture}
          disablePast={disablePast}
          views={['year', 'month', 'day']}
          label={label}
          renderInput={(params) => (
            <TextField
              size="small"
              {...otherProps}
              {...params}
              variant={otherProps.variant as any}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={onTrueBlur}
              sx={{
                ...otherProps.sx,
                '& input': {
                  textAlign: centered ? 'center' : 'left',
                },
              }}
              InputProps={{
                notched: false,
              }}
            />
          )}
        />
      ) : (
        <DesktopDatePicker
          value={selectedDate}
          onChange={handleChange}
          onClose={onClose}
          inputFormat="MM/DD/YYYY"
          disablePast={disablePast}
          disableFuture={disableFuture}
          views={['year', 'month', 'day']}
          open={open}
          dayOfWeekFormatter={(day) => dayFormat[day]}
          disableHighlightToday
          components={{
            LeftArrowIcon: () => <LeftArrowIcon width="24px" height="24px" />,
            RightArrowIcon: () => <RightArrowIcon width="24px" height="24px" />,
            SwitchViewIcon: (switchViewIconProps) => {
              const { className } = switchViewIconProps
              return (
                <Box
                  className={className}
                  display="flex"
                  alignItems="center"
                  sx={{ pointerEvents: 'none' }}
                >
                  <CaretIcon width="16px" height="16px" />
                </Box>
              )
            },
          }}
          disableOpenPicker
          label={label}
          renderInput={(params) => (
            <TextField
              size="small"
              {...otherProps}
              {...params}
              data-type="date-picker"
              variant={otherProps.variant as any}
              onBlur={onTrueBlur}
              sx={{
                ...otherProps.sx,
                '& input': {
                  textAlign: centered ? 'center' : 'left',
                },
              }}
              InputProps={{
                notched: false,
                inputProps: {
                  ...params.inputProps,
                  'data-testid': dataTestId,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => setOpen(true)}
              error={otherProps.error}
            />
          )}
        />
      )}
    </LocalizationProvider>
  )
}

export default DatePicker

DatePicker.defaultProps = {
  disablePast: false,
  disableFuture: false,
  centered: false,
}
