import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const CloseIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M9.29376 9.29278C9.38664 9.19966 9.49698 9.12579 9.61846 9.07538C9.73994 9.02497 9.87017 8.99902 10.0017 8.99902C10.1332 8.99902 10.2634 9.02497 10.3849 9.07538C10.5064 9.12579 10.6167 9.19966 10.7096 9.29278L16.0011 14.5863L21.2926 9.29278C21.3856 9.19982 21.4959 9.12607 21.6174 9.07576C21.7389 9.02544 21.8691 8.99955 22.0005 8.99955C22.132 8.99955 22.2622 9.02544 22.3837 9.07576C22.5051 9.12607 22.6155 9.19982 22.7085 9.29278C22.8014 9.38575 22.8752 9.49612 22.9255 9.61758C22.9758 9.73905 23.0017 9.86924 23.0017 10.0007C23.0017 10.1322 22.9758 10.2624 22.9255 10.3838C22.8752 10.5053 22.8014 10.6157 22.7085 10.7086L17.415 16.0001L22.7085 21.2916C22.8014 21.3846 22.8752 21.495 22.9255 21.6164C22.9758 21.7379 23.0017 21.8681 23.0017 21.9996C23.0017 22.131 22.9758 22.2612 22.9255 22.3827C22.8752 22.5042 22.8014 22.6145 22.7085 22.7075C22.6155 22.8005 22.5051 22.8742 22.3837 22.9245C22.2622 22.9748 22.132 23.0007 22.0005 23.0007C21.8691 23.0007 21.7389 22.9748 21.6174 22.9245C21.4959 22.8742 21.3856 22.8005 21.2926 22.7075L16.0011 17.414L10.7096 22.7075C10.6167 22.8005 10.5063 22.8742 10.3848 22.9245C10.2634 22.9748 10.1332 23.0007 10.0017 23.0007C9.87022 23.0007 9.74003 22.9748 9.61856 22.9245C9.49709 22.8742 9.38673 22.8005 9.29376 22.7075C9.20079 22.6145 9.12705 22.5042 9.07673 22.3827C9.02642 22.2612 9.00052 22.131 9.00052 21.9996C9.00052 21.8681 9.02642 21.7379 9.07673 21.6164C9.12705 21.495 9.20079 21.3846 9.29376 21.2916L14.5873 16.0001L9.29376 10.7086C9.20064 10.6158 9.12676 10.5054 9.07635 10.3839C9.02595 10.2625 9 10.1322 9 10.0007C9 9.86919 9.02595 9.73896 9.07635 9.61748C9.12676 9.49601 9.20064 9.38566 9.29376 9.29278Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default CloseIcon
