import { Box, MenuItem, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { CaretIcon } from '../icons/CaretIcon'

type DropdownOption = {
  label: string
  value: any
  disabled?: boolean
}

export type DropdownProps = TextFieldProps & {
  options: DropdownOption[]
  'data-testid'?: string
}

const Dropdown = (props: DropdownProps) => {
  const {
    options,
    error,
    'data-testid': dataTestId,
    value,
    ...otherProps
  } = props
  return (
    <TextField
      size="small"
      error={error}
      fullWidth
      select
      InputLabelProps={{
        shrink: true,
      }}
      {...otherProps}
      SelectProps={{
        ...{
          MenuProps:
            otherProps.size === 'dropdownMini'
              ? {
                  sx: {
                    '& .MuiPaper-root': {
                      padding: '8px 0px',
                    },
                    '& .MuiMenuItem-root': {
                      fontSize: '0.875rem',
                    },
                  },
                }
              : {},
        },
        ...otherProps.SelectProps,
        inputProps: { 'data-testid': `${dataTestId}-input` }, // to avoid name conflict with selectdisplayprops in getbytestid
        SelectDisplayProps: {
          // @ts-ignore
          'data-testid': dataTestId, // for  cypress tests since the input is aria-hidden
          'data-type': 'dropdown',
          'data-options': options.map((option) => option.value).join(','),
        },
        IconComponent: (iconProps) => {
          const { className } = iconProps
          return (
            <Box
              className={className}
              display="flex"
              alignItems="center"
              sx={{ pointerEvents: 'none' }}
            >
              <CaretIcon
                color={error ? 'error' : 'primary'}
                width="24px"
                height="24px"
              />
            </Box>
          )
        },
      }}
      value={value || ''}
    >
      {options.map((option) => {
        const { label, value: optionValue, disabled } = option
        return (
          <MenuItem
            key={label}
            value={optionValue}
            data-testid={optionValue}
            data-type="dropdown-option"
            disabled={disabled}
          >
            {label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export default Dropdown
