import { Box, SvgIconProps, Typography } from '@mui/material'
import React from 'react'

// When a text field is decorated, we want to remove all the padding
// and margins and the like.
export const DecoratedTextFieldProps = {}

export type InputDecoratorProps = {
  error?: boolean
  icon?: React.FC<SvgIconProps>
  postfix?: string
  postfixAdornment?: string
  children: React.ReactNode
}

// Adds adornments to an input such as an icon and a postfix
const InputDecorator = (props: InputDecoratorProps) => {
  const { error, icon: Icon, postfix, postfixAdornment, children } = props

  return (
    <Box display="flex" alignItems="center" gap="16px">
      {Icon && (
        <Box width="32px" height="32px">
          <Icon
            width="32px"
            height="32px"
            color={error ? 'error' : undefined}
          />
        </Box>
      )}
      {children}
      {postfix && (
        <Box display="flex" alignItems="baseline" width="fit-content">
          <Typography
            variant="p1"
            whiteSpace="nowrap"
            color={error ? 'error' : undefined}
          >
            {postfix}
          </Typography>
          &nbsp;
          <Typography variant="p2" whiteSpace="nowrap" color="gray5.main">
            {postfixAdornment}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default InputDecorator

InputDecorator.defaultProps = {
  icon: null,
  postfix: null,
  postfixAdornment: null,
  error: false,
}
